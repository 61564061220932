import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormatQuote from '@material-ui/icons/FormatQuote';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import styles from 'assets/jss/material-kit-react/views/homePageSections/guestQuotesStyle.js';

const useStyles = makeStyles(styles);

export default function GuestQuotes() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Vellum Court Guest Book</h2>
          <p className={classes.description}>
            The following quotes are taken from the guest book that we leave in
            the apartment. We&apos;ve been delighted with the kind words that
            people have left for us over the years and we hope you find it
            interesting to read their feedback on the apartment and local area.
          </p>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} md={4}>
            <InfoArea
              title="Tony & Bobby from the UK"
              description={
                '"As usual, a great apartment. Things haven\'t changed and we enjoyed ourselves with plenty of nice visits. See you again, many thanks Tony & Bobby"'
              }
              icon={FormatQuote}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <InfoArea
              title="The McMillan family from The Netherlands"
              description={
                '"Our 10 year anniversary of staying here! We enjoyed the refurbished en-suite and, as always the spacious and comfortable apartment. WIshing you a joyful 2019."'
              }
              icon={FormatQuote}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <InfoArea
              title="Gill and Chris from Canada"
              description={
                'I wish we had booked for longer. Your apartment is lovely - large, well maintained and equipped and very comfortable. Thank you for all the extra touches you have provided. Very much appreciated! '
              }
              icon={FormatQuote}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12}>
            <p style={{ textAlign: 'center' }}>
              Read more on the <a href="guest-quotes">Guest Quotes page</a>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
