/*eslint-disable*/
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import { Apps, CloudDownload } from '@material-ui/icons';

// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button href="/" color="transparent" className={classes.navLink}>
          Home
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button href="/gallery" color="transparent" className={classes.navLink}>
          Photos
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          href="/guest-quotes"
          color="transparent"
          className={classes.navLink}
        >
          Guest Book
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => {
            window.open(
              'https://www.visit-hampshire.co.uk/things-to-do/attractions'
            );
          }}
        >
          Hampshire Attractions
        </Button>
      </ListItem>

      {/* TODO: Put back in when ready to develop it <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Information"
          buttonProps={{
            className: classes.navLink,
            color: 'transparent'
          }}
          dropdownList={[
            <Link to="/eating-out" className={classes.dropdownLink}>
              Eating Out
            </Link>,
            <Link to="/havant" className={classes.dropdownLink}>
              Havant
            </Link>,
            <Link to="/visit" className={classes.dropdownLink}>
              Places to Visit
            </Link>,
            <a
              href="https://www.visit-hampshire.co.uk/things-to-do/attractions"
              target="_blank"
              className={classes.dropdownLink}
            >
              Hampshire Attractions
            </a>
          ]}
        />
      </ListItem> */}

      <ListItem className={classes.listItem}>
        <Button
          href="/contact-us"
          color="transparent"
          className={classes.navLink}
        >
          Book
        </Button>
      </ListItem>
    </List>
  );
}
