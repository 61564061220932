import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-kit-react/views/homePageSections/infoCardsStyle.js';

import langstone_img from 'assets/img/small/langstone_550_340.jpg';
import cooker_img from 'assets/img/small/cooker_550_340.jpg';
import spinnaker_img from 'assets/img/small/spinnaker_550_340.jpg';

const useStyles = makeStyles(styles);

export default function InfoCards() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <img
              className={classes.imgCardTop}
              src={langstone_img}
              alt="Card-img-cap"
            />
            <CardBody>
              <h4 className={classes.title}>About Havant</h4>
              <p className={classes.description}>
                Havant is only a 1hr 15 minute train ride to London Waterloo, 8
                miles to historic Portsmouth, 9 miles from Chichester and
                moments from the idyllic beauty of Hayling Island and the south
                coast of England.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <img
              className={classes.imgCardTop}
              src={spinnaker_img}
              alt="Card-img-cap"
            />
            <CardBody>
              <h4 className={classes.title}>Things To Do</h4>
              <p className={classes.description}>
                Vellum Court is perfectly situated to explore the south of
                England. Visit Portsmouth with it&apos;s historic dock yards,
                Spinnaker Tower and Gunwharf Quays shopping village. The
                beautiful town of Chichester is close by and to the north is a
                large area of outstanding beauty ideal for gentle walks, cycling
                and other outdoor pursuits.
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <img
              className={classes.imgCardTop}
              src={cooker_img}
              alt="Card-img-cap"
            />
            <CardBody>
              <h4 className={classes.title}>Vellum Court Apartment</h4>
              <span className={classes.description}>
                <p>High specification finish throughout.</p>
                <p>
                  Part of a private plot with access via electronically operated
                  entry gates.
                </p>
                <p>
                  Granite work tops, fridge freezer, double oven, five ring gas
                  hob, dishwasher & washing machine
                </p>
              </span>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
