import React from 'react';

import Button from 'components/CustomButtons/Button';
import Footer from 'components/Footer/Footer.js';
import Header from 'components/Header/Header.js';
import HeaderParallax from 'components/HeaderParallax/HeaderParallax';
import PageContentContainer from 'components/PageContentContainer/PageContentContainer';

import Carousel from './HomeCarousel/HomeCarousel.js';
import GuestQuotes from './GuestQuotes/GuestQuotes.js';
import InfoCards from './InfoCards/InfoCards.js';
import VellumCourt from './VellumCourtIntro/VellumCourtIntro.js';

import image from 'assets/img/vellumcourt_2048_1536.jpg';

export default function HomePage() {
  return (
    <div>
      <Header />
      <HeaderParallax image={image} title="Luxury Apartment">
        <>
          <h3>
            A stylish home from home, perfect for exploring the south coast of
            England
          </h3>
          <br />
          <Button type="button" color="success" size="lg" href="contact-us">
            Book Now
          </Button>
        </>
      </HeaderParallax>

      <PageContentContainer>
        <>
          <VellumCourt />
          <Carousel />
          <GuestQuotes />
          <InfoCards />
        </>
      </PageContentContainer>
      <Footer />
    </div>
  );
}
