import dining from 'assets/img/3x2/dining.jpg';
import ensuite1 from 'assets/img/3x2/ensuite1.jpg';
import hook from 'assets/img/3x2/hook.jpg';
import kitchen1 from 'assets/img/3x2/kitchen1.jpg';
import living4 from 'assets/img/3x2/living4.jpg';
import masterbed3 from 'assets/img/3x2/masterbed3.jpg';
import singlebed1 from 'assets/img/3x2/singlebed1.jpg';
import twinbed1 from 'assets/img/3x2/twinbed1.jpg';
import vellumcourt from 'assets/img/3x2/vellumcourt.jpg';

export const images = [
  { image: dining, caption: 'Open plan living area' },
  { image: living4, caption: '' },
  { image: kitchen1, caption: '' },
  { image: masterbed3, caption: 'Master bedroom' },
  { image: ensuite1, caption: 'Master bedroom en-suite' },
  { image: twinbed1, caption: 'Twin bedded room' },
  { image: singlebed1, caption: 'Single bedroom' },
  { image: hook, caption: '' },
  { image: vellumcourt, caption: '' }
];
