import { title } from 'assets/jss/material-kit-react.js';
import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.js';

const infoCardsStyle = {
  section: {
    padding: '1rem 0',
    textAlign: 'center'
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none'
  },
  description: {
    color: '#999'
  },
  ...imagesStyle
};

export default infoCardsStyle;
