import dvds from 'assets/img/3x2/dvds.jpg';
import bathroom1 from 'assets/img/3x2/bathroom1.jpg';
import bathroom2 from 'assets/img/3x2/bathroom2.jpg';
import bathroom3 from 'assets/img/3x2/bathroom3.jpg';
import boardgames from 'assets/img/3x2/boardgames.jpg';
import cds from 'assets/img/3x2/cds.jpg';
import dining from 'assets/img/3x2/dining.jpg';
import ensuite1 from 'assets/img/3x2/ensuite1.jpg';
import frontdoor from 'assets/img/3x2/frontdoor.jpg';
import hook from 'assets/img/3x2/hook.jpg';
import kitchen1 from 'assets/img/3x2/kitchen1.jpg';
import landing1 from 'assets/img/3x2/landing1.jpg';
import living1 from 'assets/img/3x2/living1.jpg';
import living2 from 'assets/img/3x2/living2.jpg';
import living3 from 'assets/img/3x2/living3.jpg';
import living4 from 'assets/img/3x2/living4.jpg';
import living5 from 'assets/img/3x2/living5.jpg';
import living6 from 'assets/img/3x2/living6.jpg';
import living7 from 'assets/img/3x2/living7.jpg';
import masterbed1 from 'assets/img/3x2/masterbed1.jpg';
import masterbed2 from 'assets/img/3x2/masterbed2.jpg';
import masterbed3 from 'assets/img/3x2/masterbed3.jpg';
import outside1 from 'assets/img/3x2/outside1.jpg';
import pond from 'assets/img/3x2/pond.jpg';
import singlebed1 from 'assets/img/3x2/singlebed1.jpg';
import singlebed2 from 'assets/img/3x2/singlebed2.jpg';
import singlebed4 from 'assets/img/3x2/singlebed4.jpg';
import toys from 'assets/img/3x2/toys.jpg';
import twinbed1 from 'assets/img/3x2/twinbed1.jpg';
import twinbed2 from 'assets/img/3x2/twinbed2.jpg';
import twinbed3 from 'assets/img/3x2/twinbed3.jpg';
import vellumcourt from 'assets/img/3x2/vellumcourt.jpg';
import viewtofront1 from 'assets/img/3x2/viewtofront1.jpg';
import viewtofront2 from 'assets/img/3x2/viewtofront2.jpg';
import viewtoside1 from 'assets/img/3x2/viewtoside1.jpg';

export const images = [
  { image: dining, caption: 'Open plan living area' },
  { image: living1, caption: '' },
  { image: living2, caption: '' },
  { image: living3, caption: '' },
  { image: living4, caption: '' },
  { image: living5, caption: '' },
  { image: living6, caption: '' },
  { image: living7, caption: '' },
  { image: kitchen1, caption: '' },
  { image: masterbed1, caption: 'Master bedroom' },
  { image: masterbed2, caption: 'Master bedroom' },
  { image: masterbed3, caption: 'Master bedroom' },
  { image: ensuite1, caption: 'Master bedroom en-suite' },
  { image: twinbed1, caption: 'Twin bedded room' },
  { image: twinbed2, caption: 'Twin bedded room' },
  { image: twinbed3, caption: 'Twin bedded room' },
  { image: singlebed1, caption: 'Single bedroom' },
  { image: singlebed2, caption: 'Single bedroom' },
  { image: singlebed4, caption: 'Single bedroom' },
  { image: bathroom1, caption: 'Family bathroom' },
  { image: bathroom2, caption: 'Family bathroom' },
  { image: bathroom3, caption: 'Family bathroom' },
  { image: viewtofront1, caption: '' },
  { image: viewtofront2, caption: '' },
  { image: viewtoside1, caption: '' },
  { image: landing1, caption: '' },
  { image: frontdoor, caption: '' },
  { image: hook, caption: '' },
  { image: outside1, caption: '' },
  { image: pond, caption: '' },
  { image: vellumcourt, caption: '' },
  { image: toys, caption: 'Stuff for the little ones' },
  { image: dvds, caption: 'Some of the large collection of films' },
  { image: boardgames, caption: '' },
  { image: cds, caption: '' }
];
