import imagesStyles from 'assets/jss/material-kit-react/imagesStyles.js';

const contactUsStyle = {
  ...imagesStyles,
  description: {
    marginTop: '1rem'
  }
};

export default contactUsStyle;
