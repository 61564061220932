import React from 'react';
import Carousel from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js';

import * as img from './images';

const useStyles = makeStyles(styles);

export default function GalleryCarousel() {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <Carousel {...settings}>
              {img.images.map(({ image, caption }) => (
                <div key={image}>
                  <img src={image} alt={caption} className="slick-image" />
                  <div className="slick-caption">
                    <h2>{caption}</h2>
                  </div>
                </div>
              ))}
            </Carousel>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
