import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import vrbo from 'assets/img/logos/vrbo.png';
import airbnb from 'assets/img/logos/airbnb.png';

const useStyles = makeStyles({
  linksBorder: {
    padding: '0.5rem',
    marginBottom: '0.5rem'
  }
});

export default function AdSiteLinks() {
  const classes = useStyles();
  return (
    <>
      <h2>Rental Website Links</h2>
      <p>
        For checking our availability, prices, and for booking Vellum Court, please use the links below to our adverts on the popular holiday listings sites.
      </p>
      <Paper className={classes.linksBorder}>
        <a
          href="https://www.airbnb.co.uk/rooms/17283024"
          target="_blank"
          rel="noreferrer"
          >
          <p>
            Airbnb is our preferred place to take bookings
          </p>
          <img src={airbnb} alt="Airbnb holiday rentals link" />
        </a>
      </Paper>

      <Paper className={classes.linksBorder}>
        <a
          href="https://www.vrbo.com/en-gb/p721218"
          target="_blank"
          rel="noreferrer"
        >
          <p>We also have an advert on VRBO</p>
          <img src={vrbo} alt="VRBO UK holiday rentals link" />
        </a>
      </Paper>
    </>
  );
}
