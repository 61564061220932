import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-kit-react/views/homePageSections/vellumCourtIntroStyle.js';

const useStyles = makeStyles(styles);

export default function VellumCourtIntro() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={10}>
          <h2 className={classes.title}>Vellum Court</h2>
          <p className={classes.description}>
            Vellum Court is a stunning luxury holiday apartment located
            perfectly to explore the south cost of England. The original
            building was an ancient parchment and leather works that has been
            designated as a Grade II listing building. The top floor apartment
            consists of a large open plan kitchen and living area, master
            bedroom with en-suite, second bedroom with twin beds, modern
            bathroom and a small third room/study. The apartment is part of an
            exclusive Hampshire development comprising of six of the original
            buildings of the leather works.
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
