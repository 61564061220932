import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';

import styles from 'assets/jss/material-kit-react/components/headerParallaxStyle';

const useStyles = makeStyles(styles);

export default function HeaderParallax({ children, image, imgHeight, title }) {
  const classes = useStyles();
  return (
    <Parallax filter image={image} imgHeight={imgHeight}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h1 className={classes.title}>{title}</h1>
            {children}
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
  );
}
