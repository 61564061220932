import React from 'react';

import TextField from '@material-ui/core/TextField';

import { useFormikContext } from 'formik';

export default function TextInput({
  autoFocus = false,
  children = '',
  name,
  placeholder,
  rows = 1
}) {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values
  } = useFormikContext();

  return (
    <TextField
      autoFocus={autoFocus}
      error={touched[name] && Boolean(errors[name])}
      fullWidth
      helperText={touched[name] && errors[name]}
      multiline={rows > 1}
      rows={rows}
      id={name}
      label={children}
      margin="dense"
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder}
      value={values[name]}
      variant="outlined"
    />
  );
}
