import React from 'react';

import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderParallax from 'components/HeaderParallax/HeaderParallax';
import PageContentContainer from 'components/PageContentContainer/PageContentContainer';

import headerImage from 'assets/img/vellumcourt_2048_1536.jpg';

export default function Havant() {
  return (
    <>
      <Header />
      <HeaderParallax image={headerImage} title="Havant" imgHeight="45vh">
        <h3></h3>
      </HeaderParallax>
      <PageContentContainer>
        <br />
        <p>Coming soon ..</p>
        <br />
        <br />
        <br />
        <br />
      </PageContentContainer>
      <Footer />
    </>
  );
}
