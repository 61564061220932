import React, { useState } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TextInput from 'components/TextInput/TextInput';
import Snackbar from 'components/Snackbar/Snackbar';

export default function ContactUsForm() {
  console.log('Loading Contact Us Page. API: ' + process.env.REACT_APP_API_URL);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const contactUsSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address so that we can reply to you')
      .required('Please provide your email address'),
    fullName: Yup.string().required('Please provide your name'),
    phone: Yup.string(),
    message: Yup.string().required('Please fill in the email message')
  });

  const setSnackBarOpen = (value) => {
    setSnackBar({ ...snackBar, open: value });
  };

  const initialValues = {
    email: '',
    fullName: '',
    phone: '',
    message: ''
  };

  function handleSubmit(values, { setSubmitting, resetForm }) {
    const apiUrl = process.env.REACT_APP_API_URL + '/contact-us';
    console.log('Submit Handled');
    axios
      .post(apiUrl, values)
      /* eslint-disable no-unused-vars */
      .then((response) => {
        setSnackBar({
          open: true,
          message:
            "Thank you for your message! We'll get back to you as soon as we can.",
          severity: 'success'
        });
        resetForm();
      })
      .catch((error) => {
        setSnackBar({
          open: true,
          message:
            "Ah, now that didn't work. Sorry, but please try again later.",
          severity: 'error'
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={contactUsSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <h2>Send a message</h2>
            <GridContainer justifyContent="center">
              <GridItem xs={12}>
                <TextInput name="email" placeholder="Your email address">
                  Email Address*:
                </TextInput>
              </GridItem>

              <GridItem xs={12}>
                <TextInput name="fullName" placeholder="Your name">
                  Name*:
                </TextInput>
              </GridItem>

              <GridItem xs={12}>
                <TextInput
                  name="phone"
                  placeholder="[Optional] Your phone number"
                >
                  Phone Number:
                </TextInput>
              </GridItem>

              <GridItem xs={12}>
                <TextInput name="message" placeholder="Your message" rows={10}>
                  Your Message*:
                </TextInput>
              </GridItem>

              {/* TODO: add in date pickers for check in/out - low priority 
              issue: npm error - UNABLE_TO_GET_ISSUER_CERT_LOCALLY
              possible solution - https://stackoverflow.com/a/45884819  
              */}
              {/* 
              <GridItem xs={12}>
                <DatePicker
                  component={DatePicker}
                  label="Check In:"
                  name="checkIn"
                />
              </GridItem> */}

              <GridItem xs={12}>
                {isSubmitting && <LinearProgress />}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={snackBar.open}
        message={snackBar.message}
        setOpen={setSnackBarOpen}
        severity={snackBar.severity}
      />
    </>
  );
}
