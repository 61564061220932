import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-kit-react/views/pageContentContainer';

const useStyles = makeStyles(styles);

export default function HomePage({ children }) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>{children}</div>
    </div>
  );
}
