import React from 'react';

import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderParallax from 'components/HeaderParallax/HeaderParallax';
import PageContentContainer from 'components/PageContentContainer/PageContentContainer';
import GalleryCarousel from './GalleryCarousel/GalleryCarousel.js';

import image from 'assets/img/pond_2048_1536.jpg';

export default function Gallery() {
  return (
    <div>
      <Header />
      <HeaderParallax title="Photo Gallery" image={image}>
        <h3>
          A selection of photographs of the apartment and surrounding area
        </h3>
      </HeaderParallax>

      <PageContentContainer>
        <GalleryCarousel />
      </PageContentContainer>

      <Footer />
    </div>
  );
}
