import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import HeaderParallax from 'components/HeaderParallax/HeaderParallax';
import PageContentContainer from 'components/PageContentContainer/PageContentContainer';

import AdSiteLinks from './AdSiteLinks/AdSiteLinks';
import ContactUsForm from './ContactUsForm/ContactUsForm';

import headerImage from 'assets/img/vellumcourt_2048_1536.jpg';

import styles from 'assets/jss/material-kit-react/views/contactUs';

const useStyles = makeStyles(styles);

export default function ContactUs() {
  const classes = useStyles();
  return (
    <>
      <Header />
      <HeaderParallax image={headerImage} title="Contact Us">
        <h3>
          Send us a message or instantly book via Airbnb or VRBO
        </h3>
      </HeaderParallax>

      <PageContentContainer>
        <GridContainer justifyContent="center">
          <GridItem xs={12}>
            <p className={classes.description}>
              Use the form below to ask us a question, or book instantly using the links to Airbnb or VRBO.
            </p>
          </GridItem>

          <GridItem xs={12} md={6}>
            <ContactUsForm />
          </GridItem>

          <GridItem xs={12} md={6}>
            <AdSiteLinks />
          </GridItem>
        </GridContainer>
      </PageContentContainer>
      <Footer />
    </>
  );
}
