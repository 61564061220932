import React from 'react';
import Carousel from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js';

import * as img from './images';

const useStyles = makeStyles(styles);

export default function HomeCarousel() {
  const classes = useStyles();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb'
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {img.images.map(({ image, caption }) => (
                  <div key={image}>
                    <img
                      src={image}
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>{caption}</h4>
                    </div>
                  </div>
                ))}
              </Carousel>
            </Card>
            <p style={{ textAlign: 'center' }}>
              See more photos in the <a href="gallery">Photo Gallery</a>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
