import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';

import 'assets/scss/material-kit-react.scss?v=1.9.0';

import ContactUs from 'views/ContactUs/ContactUs';
import EatingOut from 'views/EatingOut/EatingOut';
import Gallery from 'views/Gallery/Gallery';
import GuestQuotes from 'views/GuestQuotes/GuestQuotes';
import Havant from 'views/Havant/Havant';
import HomePage from 'views/HomePage/HomePage';
import Visit from 'views/Visit/Visit';

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    {console.log('Vellum Court App version: ' + process.env.REACT_APP_VERSION)}
    <Switch>
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/eating-out" component={EatingOut} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/guest-quotes" component={GuestQuotes} />
      <Route path="/havant" component={Havant} />
      <Route path="/visit" component={Visit} />
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>,
  document.getElementById('root')
);
