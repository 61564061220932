import React from 'react';

import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderParallax from 'components/HeaderParallax/HeaderParallax';
import PageContentContainer from 'components/PageContentContainer/PageContentContainer';
import FormatQuote from '@material-ui/icons/FormatQuote';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import headerImage from 'assets/img/vellumcourt_2048_1536.jpg';

import { quotes } from './quoteData';

export default function GuestQuotes() {
  return (
    <>
      <Header />
      <HeaderParallax image={headerImage} title="Guest Book">
        <h3>
          Here are some of the comments that previous guests have left in the
          guest book that we leave in the apartment
        </h3>
      </HeaderParallax>
      <PageContentContainer>
        <GridContainer style={{ textAlign: 'center' }}>
          {quotes.map((quote, index) => (
            <GridItem key={index} xs={12} md={4}>
              <InfoArea
                title={quote.guest}
                description={quote.quote}
                icon={FormatQuote}
                iconColor="info"
                vertical
              />
            </GridItem>
          ))}
        </GridContainer>
      </PageContentContainer>
      <Footer />
    </>
  );
}
